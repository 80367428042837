import styled from 'styled-components';

function typeColors(type) {
    switch (type) {
        case 'add':
            return '#70163A';
            
        case 'save':
            return '#21AB69';

        case 'delete':
            return '#F35457';

        case 'edit':
            return '#C14979';
            
        case 'export':
            return '#982B57';

        case 'return':
            return '#d68e70';
    
        default:
            return '#cecece';
    }
}

export const Button = styled.button`
    width: ${(p) => p.width};
    height: ${(p) => p.height};
    background: ${(p) => p.disabled ? '#cecece' : p.color || typeColors(p.typeButton)};
    border-radius: 25px;
    border: none;
    font-family: var(--font-button-text-primary);
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.4px;
    font-size: ${(p) => p.size ? p.size : '18px'};
    line-height: 25px;
    text-align: center;
    color: var(--color-white);
    cursor: ${(p) => p.disabled ? 'no-drop' : 'pointer'};
    padding: 12px 20px;
    box-shadow: ${(p) => p.offShadow ? null : '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'};
    transition: 0.2s;
    margin: ${(p) => p.margin};

    ${(p) => p.offShadow ? null : `
        :hover {
            margin-top: -4px;
            margin-bottom: 4px;
        }
    `};
`;

export const ContainerInputs = styled.div`
    margin-top: ${(p) => p.marginTop ? p.marginTop : '10px'};
    width: 100%;
    display: grid;
    grid-template-columns: ${(p) => p.gridColumns ? p.gridColumns : '2fr 2fr 2fr 2fr 0.1fr'};
    align-items: ${(p) => p.alignItems};
    gap: 20px;

    @media (max-width: 1100px) {
        grid-template-columns: 2fr 2fr;
    }

    @media (max-width: 800px) {
        grid-template-columns: 2fr;
    }
`;

export const SubTitle = styled.h3`
    font-family: var(--font-text-primary);
    font-style: normal;
    font-weight: ${(p) => p.light ? "500" : "700"};
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: flex-end;
    color: var(--color-subtitle-primary);
    margin: ${(p) => p.margin};
`;

export const SpanStatus = styled.span`
    font-family: var(--font-text-primary);
`;

export const SpanError = styled.span`
    font-family: var(--font-text-primary);
    color: red;
    margin: ${(p) => p.margin ? p.margin : ''};
`;

export const DivCheckbox = styled.div`
    margin: ${(p) => p.margin && p.margin};
    display: flex;
    align-items: center;

    input {
        width: 24px;
        height: 24px;
    }

    label {
        font-family: var(--font-text-primary);
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: var(--color-input-title-primary);
        margin-left: 10px;
    }
`;

export const DivFlex = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 10px;
    align-items: flex-end;
`

export const PaymentFormInputValueWrapper = styled.div`
  width: 80px;
`;

export const PaymentFormMinimalValueWrapper = styled.div`
  width: 140px;
`;

export const PaymentFormDiscountWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;

export const PaymentFormInstallmentWrapper = styled.div`
    display: flex;
    gap: 10px;
`;

export const PaymentFormInstallmentValueWrapper = styled.div`
  width: 140px;
`;
